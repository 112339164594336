import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output", "translated"];

  toggleHighlight() {
    this.outputTarget.classList.toggle("proverb-light");
    console.log("toggleHighlight");
  }
  showhide() {
    const partid = this.outputTarget.getAttribute("data-value");
    console.log(partid);
    document.getElementById(partid).classList.toggle("hidden");
    console.log("showhide");
     
  }
}